<template>
    <div class="ui grid">
        <div class="row centered">
            <div class="eight wide column">
                <h1 class="dossier-welcome text-capitalize">Bonjour {{current_user.nom}}</h1>
            </div>
        </div>
        <div class="row centered">
            <div class="eight wide column">
                <h2 class="dossier-section-title">ESPACE D'ETUDE</h2>
            </div>
        </div>
        <div class="row centered">
            <div class="twelve wide centered column">
                <div class="dossier-upload-container">
                    <template>
                        <div class="submited-title"> Bienvenue !</div>
                        <div class="submited-title">dans votre espace d'étude</div>
                        <p class="submited-message">
                            Vous pouvez suivre le processe de votre
                            <br/>  étude via la page de suivi
                        </p>
                        <div class="flex">
                            <button class="submited-button mt-2 mx-auto" @click="$router.push({name: 'suivi-etude'})">
                                Suivre les études
                            </button>

                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import uploadItem from "../components/uploadItem";
    export default {
        components:{
            uploadItem
        },
        data() {
            return {
                isBusy: false
            }
        },
        computed: {
            current_user() {
                return this.$store.state.current_user
            },
            dossier () {
                return this.$store.getters.dossier
            },
            isDossierCreated(){
                return !_.isEmpty(this.dossier)
              //  return !_.isEmpty(this.$store.getters.validated_steps)
            }
        },
        methods: {
            addEtudeProcess(){

                if(this.isBusy) return

                this.isBusy = true
                let dossier_ = this.dossier

                let data = {
                   dossier_id: dossier_._id,
                    process : 'etude'
                }

                if (!dossier_.etude){

                    this.$store.dispatchAsync(this.$SHARED.services.dossier.addProcess, data).then(ar => {
                        this.$toast.success(this.$SHARED.messages.attachment.upload_succeeded)
                        //this.$store.dispatch("get_dossier")
                        log("dossier returned is : ", ar)
                        this.$store.commit("SET_DOSSIER", ar)
                        this.$router.push({name: 'suivi-etude'})
                    }, code => {
                        this.$toast.error(this.$SHARED.messages[code])

                    }).finally(()=>{
                        this.isBusy = false
                    })

                }else {
                    this.$router.push({name: 'suivi-etude'})
                    this.isBusy = false
                }

            }
        },
        mounted() {
            //this.$store.state.current_process = 'etude';
           // this.$store.dispatch("get_steps");
        }
    }
</script>

<style scoped>
    .dossier-welcome {
        font-weight: 300;
        font-size: 4em;
        color: var(--main-color-blue);
        text-align: center;
        line-height: 1;
        padding-bottom: 0.35em;
        border-bottom: 2px solid var(--main-color-blue);
    }

    .dossier-section-title {
        color: var(--main-color-blue);
        text-align: center;
        font-weight: 800;
        font-size: 2.25em;
    }

    .dossier-upload-container {
        background-color: white;
        padding: 2em;

        box-shadow: 0px 0px 33px -8px rgba(0, 0, 0, 0.25);
        border-radius: 9px;
    }

    .dossier-upload-body {
        /* min-height: 300px; */
    }

    .dossier-upload-footer {
        margin-top: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .file-upload label {
        border-radius: 5px;
        color: #fff;
        background-color: var(--main-color-blue);
        display: inline-block;
        padding: 0.5em 1em;
        cursor: pointer;
    }

    .file-upload input[type=file] {
        display: none;
    }

    .upload-submit {
        border: none;
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        background-color: var(--main-color-green);
        padding: 0.75em 1em;
        cursor: pointer;
    }

    .submited-title {
        font-weight: 700;
        font-size: 2.25em;
        text-transform: uppercase;
        line-height: 1.5;
        text-align: center;
        color: var(--main-color-orange);
    }

    .submited-message {
        font-weight: 400;
        font-size: 2.125em;
        /* text-transform: uppercase; */
        line-height: 1.5;
        text-align: center;
        color: var(--main-color-blue);
    }

    .submited-button {
        border: none;
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        background-color: var(--main-color-blue);
        padding: 0.75em 1em;
        cursor: pointer;
    }
</style>
